import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApRedemptionService {


    list_recent() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_redemption/list_recent", form);

    }

    view_redemption(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_redemption/view_redemption", form);

    }  
    
    search_redemption(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_redemption/search_redemption", form);

    }

    update_daddress(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_redemption/update_daddress", form);

    }  
    

    update_status(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        //console.log(form);
        return axios.post("ap_redemption/update_status", form);

    }

}
    
 

export default new ApRedemptionService();